import React from "react";
import { Nav } from "react-bootstrap";
import "./Footer.scss";

const Footer = () => {
  return (
    <div className="footer ">
      <div className="container">
        <div className="social-media">
          <div className="single-media">
            <Nav.Link href="https://www.linkedin.com/company/button-man-visuals/about/?viewAsMember=true" target='_blank' className="media-item">
            <i className="fab fa-linkedin-in"></i>
            </Nav.Link>
          </div>
          <div className="single-media">
            <Nav.Link href="https://www.instagram.com/buttonman_visuals/?hl=en" target='_blank' className="media-item">
              <i className="fab fa-instagram"></i>
            </Nav.Link>
          </div>
          <div className="single-media">
            <Nav.Link href="https://twitter.com/buttonman_club" target='_blank' className="media-item">
              <i className="fab fa-twitter"></i>
            </Nav.Link>
          </div>
        </div>
        <div className="copyright">
          <div>
            <span>
              Copyright &copy; 2022 ButtonMan Club. All Right Reserved. ButtonMan and the B Play logo are a registered trademark of the company.  
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
