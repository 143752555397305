import React, { useEffect } from "react";
import Particle from "react-particles-js";
import AOS from "aos";
import "./ComingSoon.scss";
import particlesConfig from "../../assets/particlesConfig.json";
import GlobeAnimaton from "../GlobeAnimaton";
import { CardData } from "./CardData";

const ComingSoon = () => {
  useEffect(() => {
    // AOS.init({ once: false });
    // AOS.refresh();
  }, []);
  return (
    <div className="comming-soon">
      <Particle
        params={particlesConfig}
        className="App-particles__container particle"
      />
      <GlobeAnimaton />
      <div className="container">
        <div>
          <h1 className="title buttonman-club" data-aos="fade-down">
            BUTTONMAN CLUB
          </h1>
          <p className="we-shoot-people" data-aos="fade-down">
            WE SHOOT PEOPLE FOR MONEY
          </p>
          <div className="coming-soon-list">
            {CardData.map((card, i) => (
              <div
                className="single-list"
                key={i}
                data-aos="fade-right"
                data-aos-delay={100 * i}
              >
                <div className="single-card">
                  <img
                    className="card-frame"
                    src="/img/card-frame.png"
                    alt="frame"
                  />
                  <div className="card-1">
                    <div className="card-face card-face-front">
                      <video
                        onMouseOver={(event) => event.target.play()}
                        onMouseOut={(event) => event.target.pause()}
                        loop
                        autoPlay={window.innerWidth < 767}
                        muted
                        playsInline
                        className="frame-img"
                      >
                        <source src={card.video} type="video/mp4" />
                      </video>
                    </div>
                  </div>
                </div>
                <p className="heading">{card.title}</p>
                <div className="card-content">
                  {card.description.map((content, j) => (
                    <p key={`content-${j}`}>{content}</p>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div>
          <p className="title coming-soon-heading" data-aos="fade-up">
            COMING SOON
          </p>
        </div>
        <div className="buttonman-footer">
          <span>All Rights Reserved &copy; BUTTONMAN CLUB Right 2022-23.</span>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
