import React, { useState } from "react";
import { NavLink, Nav, Navbar, Button } from "react-bootstrap";
import "./Header.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "../Footer/Footer";
import { Modal } from "antd";
import  ContactForm  from '../ContactForm/ContactForm';
// import './fonts/Epsum-Black-Italic.otf';
const Header = () => {
  const [isFormActive, setIsFormActive] = useState(false);
  return (
    <div className="button-man">
      <Modal
        className="contact-form-modal"
        footer={null}
        visible={isFormActive}
        onCancel={() => setIsFormActive(false)}
      >
        <ContactForm />
      </Modal>
      <div className="main-header">
        <div className="container">
          <div
            className="header circular"
            data-aos="fade-in"
            data-aos-duration="600"
          >
            <Navbar collapseOnSelect expand="lg">
              {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand> */}
              <div className="logo">
                <Navbar.Brand href="/" className="img-responsive">
                  <img src="/img/Buttonman-logo-1.png" alt="" />
                </Navbar.Brand>
              </div>
              <Navbar.Toggle
                aria-controls="responsive-navbar-nav"
                className="responsive-menu"
              />
              <Navbar.Collapse id="responsive-navbar-nav " className="nav-bar">
                <Nav className="me-auto ">
                  {/* <Nav.Link href="#" className="nav-link">
                    Contact
                  </Nav.Link>
                  <Nav.Link href="/landing" className="nav-link">
                    About Us
                  </Nav.Link> */}
                  <Nav.Link href="/shoot" target="_blank" className="nav-link">
                    SHOOT
                  </Nav.Link>
                </Nav>
                <Nav>
                  {/* <Nav.Link href="#" className="nav-link">
                    Services
                  </Nav.Link>
                  <Nav.Link href="/part" className="nav-link">
                    Partners
                  </Nav.Link> */}
                  <Nav.Link
                    href="/file/White-paper.pdf"
                    target="_blank"
                    className="nav-link"
                  >
                    WHITE PAPER
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </div>
        <div className="main-banner">
          <div className="gradent"></div>
          <img
            className="img-responsive"
            src="/img/pexels-kaique-rocha-598917.jpg"
            alt=""
            data-aos="fade-in"
          />
        </div>
        <div className="we-shoot-for-money" data-aos="zoom-out">
          <img src="/img/Buttonman-asset-3.png" alt="" />
        </div>
      </div>
      <div className="are-you-ready">
        <div className="container">
          <p className="ready-heading title" data-aos="fade-left">
            ARE YOU READY?
          </p>
          <p className="get-client-to-sign  circular" data-aos="fade-up">
            Experience the Web3 cameras revolution where freedom, community and
            creativity unite using blockchains. It's time to launch a massive
            initiative that will change the creative decision-making and funding
            of film and photography for ever. ButtonMan DAO will be like
            lighting in a bottle for the future of DAO governance.
          </p>
          <span className="join-us" data-aos="fade-up">
            <a
              onClick={()=> setIsFormActive(true)}
              className="join-us-btn title"
            >
              <span></span>
              JOIN US
            </a>
          </span>
        </div>
        <div className="container">
          <div className="project-heading" data-aos="fade-right">
            <p className="title">PROJECT BACKGROUND</p>
          </div>
        </div>
        <div className="project-background">
          <div className="camera-banner">
            <img
              className="camera-img"
              src="/img/Buttonman-wsebsite-01.png"
              alt=""
            />
          </div>

          <div className="container">
            <div className=" project-list circular">
              <div
                className="single-list "
                data-aos="fade-right"
                data-aos-delay={0}
              >
                <p className="heading">Venture backed</p>
                <img src="/img/venture-backed.png" alt="" />
                <p className="single-list-content">
                  To provide adequately funded innovation, ButtonMan will
                  provide high value resources, allwoing the community to use
                  the platform to navigate regulatory hurdles while using smart
                  contracts.
                </p>
              </div>
              <div
                className="single-list"
                data-aos="fade-right"
                data-aos-delay={100}
              >
                <p className="heading">Digital revolution</p>
                <img src="/img/digital-revolution-2.png" alt="" />
                <p className="single-list-content">
                  Every single frame crafted by buttonmen of this community will
                  be converted into an NFT and will be part of metaverse with
                  digital ownership of digital assets to creators directly.
                </p>
              </div>
              <div
                className="single-list"
                data-aos="fade-right"
                data-aos-delay={200}
              >
                <p className="heading">DAO</p>
                <img src="/img/dao.png" alt="" />
                <p className="single-list-content">
                  Buttonman club will be decentralised, community driven,
                  independent club based in metaverse Powered by blockchain
                  technology, true digital democracy will be implemented for
                  artists
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="road-map">
        <div className="container">
          <p className="title road-map-heading" data-aos="fade-down">
            ROADMAP
          </p>
        </div>
        <div className="">
          <div className="gradient"></div>
          <img src="/img/roadmap.jpg" alt="" />
          <div className="gradient-bottom"></div>
        </div>
      </div>
      <div className="partners">
        <div className="container">
          <p className="title partner-heading" data-aos="fade-up">
            PARTNERS
          </p>
          <div className="partners-list row">
            <div
              className="partners-signle-list col-md-4 col-4"
              data-aos="fade-right"
              data-aos-delay="0"
            >
              <img src="/img/dji-1-logo.png" alt="" />
            </div>
            <div
              className="partners-signle-list col-md-4 col-4"
              data-aos="fade-right"
              data-aos-delay="30"
            >
              <img src="/img/sony.png" alt="" />
            </div>
            <div
              className="partners-signle-list col-md-4 col-4"
              data-aos="fade-right"
              data-aos-delay="60"
            >
              <img src="/img/canon.png" alt="" />
            </div>
            <div
              className="partners-signle-list col-md-4 col-4"
              data-aos="fade-right"
              data-aos-delay="90"
            >
              <img src="/img/Nikon-Logo1.png" alt="" />
            </div>
            <div
              className="partners-signle-list col-md-4 col-4"
              data-aos="fade-right"
              data-aos-delay="120"
            >
              <img src="/img/gopro-logo1.png" alt="" />
            </div>
            <div
              className="partners-signle-list col-md-4 col-4"
              data-aos="fade-right"
              data-aos-delay="150"
            >
              <img src="/img/pentax.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Header;
