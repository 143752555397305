export const CardData = [{
        video: '/video/bman3.mp4',
        title: 'Cinematic Revolution',
        description: ['Full potential of a lens unlocked', 'Frame craftsmanship finer than samurai swords', 'Shots that hits you point blank', 'Screen that makes you feel something']
    },
    {
        video: '/video/bman1.mp4',
        title: 'Freedom of Dream',
        description: ['Getting paid for living your dream life and working with things that you are actually', 'Passionate about, no corporate caps, true independence through community driven lifestyle']
    },
    {
        video: '/video/bman2.mp4',
        title: 'DAO',
        description: ['Decentralised Governance System', 'Every decision made by community', 'True Digital democracy', 'Every member part of Underworld (metavesre) powered by blockchain', 'Transparency , authenticity , acceptance.']
    }
]