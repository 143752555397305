import React, { FC, useState } from "react";
import s from "./ContactForm.module.scss";
import { Button, Form, Input, notification } from "antd";
import { FormRule } from "./formRule";
import { contactService } from "../../services/misc";

const ContactForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async (formData) => {
    setIsLoading(true);

    try {
      const response = await contactService({
        ...formData,
      });
      notification.success({
        message: "Thank You for contacting us. We will get back to you soon.",
      });
    } catch (error) {
      setIsLoading(false);
      if (error.response.data.message) {
        notification.error({ message: error.response.data.message });
        return;
      }
      notification.error({ message: "Something went wrong" });
    }
    setIsLoading(false);
  };
  return (
    <div className={s.container}>
      <div className="container-1">
        <div className={s.content}>
          <h1>Join Us</h1>
          <Form onFinish={onFinish}>
            <div className="row">
              <div className="col-md-6">
                <span className={s.label}>Name</span>
                <Form.Item name="Name" rules={FormRule.commonRequired}>
                  <Input />
                </Form.Item>
              </div>

              <div className="col-md-6">
                <span className={s.label}>Email</span>
                <Form.Item name="Email" rules={FormRule.email}>
                  <Input />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <span className={s.label}>Twitter</span>
                <Form.Item name="Twitter">
                  <Input />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <span className={s.label}>Instagram</span>
                <Form.Item name="Instagram" rules={FormRule.commonRequired}>
                  <Input />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <span className={s.label}>Phone No</span>
                <Form.Item name="Phone" rules={FormRule.commonRequired}>
                  <Input />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <span className={s.label}>Occupation</span>
                <Form.Item name="Occupation">
                  <Input />
                </Form.Item>
              </div>
              <div className="col-md-12">
                <span className={s.label}>
                  Describe your message here
                </span>
                <Form.Item name="HowFindOut" rules={FormRule.commonRequired}>
                  <Input.TextArea rows={5} />
                </Form.Item>
              </div>
            </div>
            <div className={s.fromButton}>
              <Button
                type="primary"
                htmlType="submit"
                loading={isLoading}
                className={s.btn}
              >
                <span>Submit</span>
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
