// import logo from './logo.svg';
import "./App.css";
import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

import { ComingSoon } from "./component/ComingSoon";
import { Header } from "./component/Header";
import { Route, Routes } from "react-router-dom";
import { AboutUs } from "./component/AboutUs";
import Partners from "./component/Partners/Partners";

function App() {
  useEffect(() => {
    AOS.init({once: false});
    setTimeout(()=>{
      AOS.refresh();
    }, 1500)
  }, []);
  return (
    <div>
      <Routes>
        <Route path="/" element={<Header />} />
        <Route path="/shoot" element={<ComingSoon />} />
      </Routes>

    </div>
  );
}

export default App;
